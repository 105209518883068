export const articles1 = [
  {
    cover: {
      src: 'https://assets.maccarianagency.com/the-front/photos/blog/cover1.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/blog/cover1.jpg 2x',
    },
    title: 'Simple approach to Australian cafe',
    subtitle:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis nec condimentum quam. Fusce pellentesque faucibus lorem at viverra. Integer at feugiat odio. In placerat euismod risus proin erat purus.',
    author: {
      photo: {
        src: 'https://assets.maccarianagency.com/the-front/photos/people/veronica-adams.jpg',
        srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/veronica-adams@2x.jpg 2x',
      },
      name: 'Veronica Adams',
    },
    date: '04 Aug',
    tags: ['larq', 'bottle', 'shop', 'drinks', 'eco', 'self washing'],
  },
  {
    cover: {
      src: 'https://assets.maccarianagency.com/the-front/photos/blog/cover2.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/blog/cover2.jpg 2x',
    },
    title: 'Adidas will release your favourite shoes',
    subtitle:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis nec condimentum quam. Fusce pellentesque faucibus lorem at viverra. Integer at feugiat odio. In placerat euismod risus proin erat purus.',
    author: {
      photo: {
        src: 'https://assets.maccarianagency.com/the-front/photos/people/akachi-luccini.jpg',
        srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/akachi-luccini@2x.jpg 2x',
      },
      name: 'Akachi Luccini',
    },
    date: '04 Aug',
    tags: ['adidas', 'sport', 'shop', 'training'],
  },
];

export const articles2 = [
  {
    cover: {
      src: 'https://assets.maccarianagency.com/the-front/photos/blog/cover3.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/blog/cover3.jpg 2x',
    },
    title: 'NIKE Online Store launches the website‎',
    subtitle:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis nec condimentum quam. Fusce pellentesque faucibus lorem at viverra. Integer at feugiat odio. In placerat euismod risus proin erat purus.',
    author: {
      photo: {
        src: 'https://assets.maccarianagency.com/the-front/photos/people/jack-smith.jpg',
        srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/jack-smith@2x.jpg 2x',
      },
      name: 'Jack Smith',
    },
    date: '04 Aug',
    tags: ['nike', 'sport', 'shop', 'training'],
  },
  {
    cover: {
      src: 'https://assets.maccarianagency.com/the-front/photos/blog/cover4.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/blog/cover4.jpg 2x',
    },
    title: "LARQ | World's First Self-cleaning Water Bottle‎",
    subtitle:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis nec condimentum quam. Fusce pellentesque faucibus lorem at viverra. Integer at feugiat odio. In placerat euismod risus proin erat purus.',
    author: {
      photo: {
        src: 'https://assets.maccarianagency.com/the-front/photos/people/kate-segelson.jpg',
        srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/kate-segelson@2x.jpg 2x',
      },
      name: 'Kate Segelson',
    },
    date: '04 Aug',
    tags: ['larq', 'bottle', 'shop', 'drinks', 'eco', 'self washing'],
  },
];

export const articles3 = [
  {
    cover: {
      src: 'https://assets.maccarianagency.com/the-front/photos/blog/cover2.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/blog/cover2.jpg 2x',
    },
    title: 'Adidas will release your favourite shoes',
    subtitle:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis nec condimentum quam. Fusce pellentesque faucibus lorem at viverra. Integer at feugiat odio. In placerat euismod risus proin erat purus.',
    author: {
      photo: {
        src: 'https://assets.maccarianagency.com/the-front/photos/people/akachi-luccini.jpg',
        srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/akachi-luccini@2x.jpg 2x',
      },
      name: 'Akachi Luccini',
    },
    date: '04 Aug',
    tags: ['adidas', 'sport', 'shop', 'training'],
  },
  {
    cover: {
      src: 'https://assets.maccarianagency.com/the-front/photos/blog/cover3.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/blog/cover3.jpg 2x',
    },
    title: 'NIKE Online Store launches the website‎',
    subtitle:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis nec condimentum quam. Fusce pellentesque faucibus lorem at viverra. Integer at feugiat odio. In placerat euismod risus proin erat purus.',
    author: {
      photo: {
        src: 'https://assets.maccarianagency.com/the-front/photos/people/jack-smith.jpg',
        srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/jack-smith@2x.jpg 2x',
      },
      name: 'Jack Smith',
    },
    date: '04 Aug',
    tags: ['nike', 'sport', 'shop', 'training'],
  },
  {
    cover: {
      src: 'https://assets.maccarianagency.com/the-front/photos/blog/cover4.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/blog/cover4.jpg 2x',
    },
    title: "LARQ | World's First Self-cleaning Water Bottle‎",
    subtitle:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis nec condimentum quam. Fusce pellentesque faucibus lorem at viverra. Integer at feugiat odio. In placerat euismod risus proin erat purus.',
    author: {
      photo: {
        src: 'https://assets.maccarianagency.com/the-front/photos/people/kate-segelson.jpg',
        srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/kate-segelson@2x.jpg 2x',
      },
      name: 'Kate Segelson',
    },
    date: '04 Aug',
    tags: ['larq', 'bottle', 'shop', 'drinks', 'eco', 'self washing'],
  },
];
